<template>
  <div id="practices-container">
    <h2><i class="fad fa-fw fa-box-full"></i> Best Practices</h2>
    <p class="lead">
      Collect your Best Practices here and have an inspiration pool of how the internet and your
      <router-link to="/benchmarks">Competition</router-link> is handling everyday tasks, or industry specific actions
      required by the user. You can also directly add <router-link to="/ideas">Ideas</router-link> from here.
      <br>
    </p>

    <ComingSoonBanner></ComingSoonBanner>

    <!--<div class="navbar sticky-top navbar-expand-lg navbar-light bg-light rounded mt-4 mb-4">
      <span class="navbar-brand mb-0 h1">Filter</span>
      <div class="navbar-collapse">
        <ul class="navbar-nav d-flex justify-content-around">
          <li v-for="(filter, fIndex) in Object.keys(practiceFilters)" class="nav-item dropdown" :key="'f' + fIndex">
            <a @click="togglePracticeFilterDropdown(filter)" class="nav-link dropdown-toggle" style="text-transform: capitalize">{{ filter }}</a>
            <div class="dropdown-menu" :class="filtersOpen.indexOf(filter) >= 0 ? 'show' : ''">
              <a @click="togglePracticeFilter(filter, 'none')" class="dropdown-item text-muted" href="javascript:void(0)">Alle entfernen</a>
              <a @click="togglePracticeFilter(filter, 'all')" class="dropdown-item text-muted" href="javascript:void(0)">Alle hinzufügen</a>
              <div class="dropdown-divider"></div>
              <div class="scroll-container">
                <div v-for="(option, oIndex) in practiceFilters[filter]" @click="togglePracticeFilter(filter, option)" class="dropdown-item" :key="'f' + fIndex + 'o' + oIndex">
                  <a href="javascript:void(0)">{{ option }}</a>
                  <i v-if="appliedFilters[filter].indexOf(option) >= 0" class="far fa-check text-success float-right mt-1"></i>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <h3 class="h5 small text-right">Results ({{ filteredPractices.length }} Examples)</h3>
    <div class="row">
      <div v-for="practice in practicesView" :id="practice.id" class="col-sm-6 mb-4" :key="practice.id">
        <Practice :practice="practice"></Practice>
      </div>
    </div>

    <button v-if="viewLimit < practices.length" @click="viewLimit += 30" class="btn btn-secondary btn-block mb-3">30 weitere anzeigen</button>-->
  </div>
</template>

<script>
// @ is an alias to /src
/*import Practice from "@/components/Practice";*/
import ComingSoonBanner from "@/components/ComingSoonBanner";

export default {
  name: 'Practices',
  components: {
    ComingSoonBanner,
    /*Practice*/
  },
  data: () => { return {
    viewLimit: 30,
    filtersOpen: [],
    appliedFilters: {
    },
  }},
  computed: {
    practices() {
      return this.$store.state.practices;
    },
    practiceFilters() {
      return this.$store.state.practiceFilters;
    },
    filteredPractices() {
      let filtered = this.practices;

      Object.keys(this.practiceFilters).forEach(attribute => {
        filtered = filtered.filter(practice => {
          let included = false;
          if (Array.isArray(practice[attribute])) {
            if (practice[attribute].length > 0) {
              practice[attribute].forEach(option => {
                included = this.appliedFilters[attribute].indexOf(option) >= 0;
              });
            } else {
              included = this.appliedFilters[attribute].length === this.practiceFilters[attribute].length;
            }
          } else {
            included = this.appliedFilters[attribute].indexOf(practice[attribute]) >= 0;
          }
          return included;
        });
      });

      return filtered;
    },
    practicesView() {
      return this.filteredPractices.slice(0, this.viewLimit);
    },
  },
  methods: {
    togglePracticeFilterDropdown(filter) {
      var open = this.filtersOpen;
      if (open.indexOf(filter) >= 0) {
        this.filtersOpen.splice(open.indexOf(filter), 1);
      } else {
        this.filtersOpen.splice(open.indexOf(filter), 1);
        this.filtersOpen.push(filter);
      }
    },
    togglePracticeFilter(filter, option) {
      if (option === 'none') {
        this.appliedFilters[filter].splice(0,this.appliedFilters[filter].length);
      } else if (option === 'all') {
        this.appliedFilters[filter].splice(0,this.appliedFilters[filter].length);
        this.appliedFilters[filter].splice(0,0, ...this.practiceFilters[filter]);
      } else {
        var options = this.appliedFilters[filter];
        if (options.indexOf(option) >= 0) {
          this.appliedFilters[filter].splice(options.indexOf(option), 1);
        } else {
          this.appliedFilters[filter].push(option);
        }
      }
    },
  },
  beforeMount() {
    this.appliedFilters = JSON.parse(JSON.stringify(this.practiceFilters));
  }
}
</script>
